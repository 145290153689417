import type { MailDataRequired } from '@sendgrid/mail'

export default async function sendEmail(model: MailDataRequired): Promise<boolean> {
  const siteStore = useSiteStore()

  const response = await $fetch('/api/sendgrid/sendemail', {
    body: model,
    method: 'POST' 
  });

  return response.status == 200;

}
